<template src="./quiz-results.html"></template>
<script>
import quizService from "../../services/quiz-service";
import appHeader from "../../../shared-module/components/app-header/app-header.vue";
var chevronListBox;
var feedback = false;
import { exportToCSV } from "./../../services/csvService";
export default {
  name: "app-quiz-results",
  components: {
    "app-header": appHeader,
  },
  data: () => {
    return {
      feedback,
      quizId: "",
      sessionId: "",
      searchResults: "",
      userName: "",
      instituteName: "",
      userEmail: "",
      userSearchResults: "",
      searchFlag: true,
      quizName: "",
      quizDuration: "",
      countQuestions: "",
    };
  },
  mounted() {
    if (this.$route.query.sessionId) {
      this.sessionId = this.$route.query.sessionId;
      this.getResultsBySessionId();
    } else if (this.$route.query.quizId) {
      this.quizId = this.$route.query.quizId;
      this.getResultsByQuizId();
      this.getCountOfQuesstions();
    }
  },
  methods: {
    async getCountOfQuesstions() {
      try {
        let response = await quizService.getQuizQuestionCount(this.quizId);
        this.countQuestions = response.data.data.question;
      } catch (error) {
        console.error(error);
      }
    },
    chevronListBoxHandler() {
      this.chevronListBox = !this.chevronListBox;
    },
    async getSessionByQuizId() {
      try {
        let response = await quizService.getSessionsByQuizId(this.quizId);

        let minuts = response.data.data.sessions[0].quiz_id.duration;
        let minutes = Math.floor(minuts / 60);
        this.quizDuration = minutes;
        this.sessionId = response.data.data.sessions[0]._id;
        this.getResultsBySessionId();
      } catch (error) {
        console.error(error);
      }
    },
    async getResultsBySessionId() {
      try {
        let response = await quizService.getResultsBySessionId(this.sessionId);
        this.searchResults = response.data.data.results;
        this.quizName = response.data.data.results[0].quiz.name;
        this.countQuestions = response.data.data.results[0].questions.length;
      } catch (error) {
        console.error(error);
        this.$toast.error("Results Not Found");
      }
    },
    async getResultsByQuizId() {
      try {
        let response = await quizService.getResultsByQuizId(this.quizId);
        this.searchResults = response.data.data.results;
        this.quizName = response.data.data.results[0].quiz.name;
      } catch (error) {
        console.error(error);
        this.$toast.error("Results Not Found");
      }
    },
    searchFlagHandler(args) {
      if (args === 1) {
        this.searchFlag = false;
      } else {
        this.searchFlag = true;
      }
    },
    async searchUser(key) {
      try {
        let data = { quiz_id: this.quizId };
        data["keyword"] = this[key];
        data["key"] = key;
        let response = await quizService.searchUser(data);
        if (response) {
          this.userSearchResults = response.data.data.response;
          this.searchFlagHandler(1);
          this.userName = "";
          this.instituteName = "";
          this.userEmail = "";
        }
      } catch (error) {
        this.$toast.error("Search not Found!");
      }
    },
    exportCSV(x) {
      // if (!x) return console.log(this.searchFlag);
      const results = [];
      const headers = [
        "Student ID",
        "Signup Date",
        "Student Name",
        "Email",
        "phone",
        "Score",
        "Date",
        "City",
        "Insitute",
        "Time/Question",
        "Obtained Marks",
        "Skipped Count",
        "Marked Questions",
        "result",
      ];
      results.push(headers);

      const d_to_attreate = this.searchFlag
        ? this.searchResults
        : this.userSearchResults;

      d_to_attreate.forEach((result, index) => {
        let city, uni;
        if(result.user.city) {
          city = result.user.city.replace(/,/g, " ");

        }
        if(result.user.university) {
          uni = result.user.university.replace(/,/g, " ");

        }
        let data = [
          result.user.id,
          result.user.created_at,
          result.user.full_name,
          result.user.email,
          result.user.phone,
          result.total_marks,
          result.created_at,
          city,
          uni,
          result.time_spent,
          result.obtained_marks,
        ];
        data.push(
          result.skipped_questions.length > result.questions.length
            ? result.skipped_questions.length - 1
            : result.skipped_questions.length
        );

        data.push(result.marked_questions.length);
        data.push(result.result ? "pass" : "fail");
        results.push(data);
      });
      exportToCSV(results, `Quiz Result.csv`);
    },
    gotoResult(result) {
      const quiz = result.quiz;
      if(quiz.quiz_type === 'daq') {
        const url = `${process.env.VUE_APP_SHARE_ABLE_LINK_POP}course/${quiz.module.index_url}/${quiz.module_id}/${quiz._id}?daq=true&daq_id=${quiz._id}&result=true&result_id=${result._id}&courseIndexUrl=${quiz.course.index_url}`;
        window.open(url, '_blank');
      } else {
        this.$router.push('/quiz/individual-results?resultId='+result._id); 
      }
    },
  },
};
</script>

<style src="./quiz-results.css" scoped></style>
